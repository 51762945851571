<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title>
          <div v-if="orden_id > 0">Ver Recepciones para la Orden de Compra N° {{ orden_id }}</div>
          <div v-if="factura_id > 0">Ver Recepciones para la Factura de Compra N° {{ factura_id }}</div>
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="auditoria == 1 ? headers_2 : headers"
                :items="recepciones"
                :loading="load"
                dense
                :search="search"
                item-key="id"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Recepciones para mostrar.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon :color="auditoria == 1 ? 'info' : 'success'" @click="verRecepcion(item)" v-on="on">
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="auditoria==1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="error" @click="eliminarRecepcion(item)" v-on="on">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="auditoria==1">
           <v-col cols="12" class="d-flex justify-end">
              <v-btn color="error" @click="activo = false">
                Cancelar
              </v-btn>
              <v-btn class="ml-2" color="info" @click="asociarRecepciones()" :disabled="desactivar">
                Guardar cambios
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="auditoria==1">
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="[
                  { text: 'Recepción ID', align: 'center', value: 'recepcion_id' },
                  { text: 'Descripción', align: 'center', value: 'descripcion' },
                  { text: 'Acciones', align: 'center', value: 'acciones' }
                ]"
                :items="todasRecepciones"
                :loading="load_2"
                dense
                :search="search_2"
                item-key="recepcion_id"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search_2"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Recepciones para mostrar.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="agreagrRecepcion(item)" v-on="on">
                        <v-icon small>fas fa-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
           <v-col cols="12" class="d-flex justify-end">
              <v-btn color="info" @click="buscarRecepcionesTodas" :disabled="desactivar">
                Buscar Recepciones
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import SearchDataTable from '../util/SearchDataTable.vue'
import { order_list_by } from '../../util/utils'

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalVerRecep', value)
      }
    },
    orden_id: {
      get(){
        return this.datos.orden_id
      }
    },
    factura_id: {
      get(){
        return this.datos.factura_id
      }
    },
    auditoria:{
      get(){
        return this.datos.auditoria
      }
    },
    proveedor_codigo:{
      get(){
        return this.datos.proveedor_codigo
      }
    },
  },
  data() {
    return {
      headers: [
        { text: 'Id', align: 'center', value: 'id' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Documento', align: 'center', value: 'documento' },
        { text: 'Deposito', align: 'center', value: 'deposito' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers_2: [
        { text: 'Recepción ID', align: 'center', value: 'id' },
        { text: 'Descripción', align: 'center', value: 'descripcion' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      todasRecepciones: [],
      recepciones: [],
      load: false,
      load_2: false,
      search: '',
      search_2:'',
      desactivar: false,
    }
  },
  methods: {
    async initModal(){
      this.load = true;  this.load_2 = true;
      let recepPeticion = await this.$store.dispatch('ordenesCompra/getRecepciones', {orden_id: this.orden_id, factura_id: this.factura_id})
      let res;
      if(this.auditoria == 1){
        res = await this.$store.dispatch('facturasCompra/obternerRecepcioneXproveedor', {proveedor_codigo: this.proveedor_codigo, opcion: 1})
      }
      this.load = false; this.load_2 = false;
      if (recepPeticion.resultado == 1){
        this.recepciones = recepPeticion.recepciones
        if(this.auditoria==1 && res.resultado == 1)  this.todasRecepciones = res.recepciones;
      }else{
        this.$store.dispatch('show_snackbar', {
          text: recepPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarModal(){
      this.todasRecepciones = [];
      this.recepciones = []
      this.load = false
      this.load_2 = false;
      this.search = ''
      this.search_2 = '';
    },
    verRecepcion(item){
      // abro la recepción en otra pestaña
      let path = '/ver-recepcion/' + item.id.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    asociarRecepciones(){ //boton verde guardar cambios
      this.$swal.fire({
        icon: 'warning',
        title: `¿Confirma asociar las Recepciones a la Factura de Compra ${this.factura_id}?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
      }).then(async (res) => {
        if(res.isConfirmed){
          const recepcionesGuardadas = this.recepciones.map(elem => ({ id: elem.id, new_id: elem.new_id,/*orden_id: elem.orden_id*/ }));
          
          this.$store.state.loading = true
          await this.$store.dispatch('facturasCompra/guardarRecepcionesXfacturaCompra',{
            factura_id: this.factura_id,
            recepciones: recepcionesGuardadas,
          })
            .then(async (res) => {
              if(res.resultado == 1){
                await this.$swal.fire({icon: 'success', title: `Recepciones asociadas correctamente.`, timer: 4000})
                this.activo = false; //cierra el modal
              }
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
    agreagrRecepcion(item){
      /*console.log("item: ", item);
      return*/
      const pos = this.recepciones.map(elem => elem.id).indexOf(item.recepcion_id);
      if(pos == -1){
        if(this.desactivar && this.recepciones.length == 0) this.desactivar = false;
        //agregar al array recepciones
        this.recepciones.push({
          deposito: item.deposito,
          descripcion: item.descripcion,
          documento: item.documento,
          fecha: item.fecha,
          id: item.recepcion_id,
          new_id: 0,
          usuario: item.usuario,
        });
        order_list_by(this.recepciones, 'id');
        //elimina de todasRecepciones
        this.todasRecepciones = this.todasRecepciones.filter(elem => elem.recepcion_id != item.recepcion_id)
      }else{
        this.$swal.fire({
          icon: 'error',
          title: `Asociar Recepción`,
          text: 'La recepción ya se encuentra asociada a la Factura de Compra.',
        })
      }
    },
    eliminarRecepcion(item){
      /*console.log("item: ", item);
      return*/
      const pos = this.todasRecepciones.map(elem => elem.recepcion_id).indexOf(item.id);
      if(pos == -1){
        //agregar al array recepciones
        this.todasRecepciones.push({
          deposito: item.deposito,
          descripcion: item.descripcion,
          documento: item.documento,
          fecha: item.fecha,
          recepcion_id: item.id,
          id: item.id, //preguntar si es correcto en 0 o el mismo item.id
          //new_id: 0, //preguntar
          usuario: item.usuario,
        });
        order_list_by(this.recepciones, 'id');
        //elimina de todasRecepciones
      }
      this.recepciones = this.recepciones.filter(elem => elem.id != item.id)
      //if(this.recepciones.length == 0) this.desactivar = true;
    },
    async buscarRecepcionesTodas(){
      this.load_2 = true;
      this.todasRecepciones = [];
      const res = await this.$store.dispatch('facturasCompra/obternerRecepcioneXproveedor', {proveedor_codigo: this.proveedor_codigo, opcion: 0})
      this.load_2 = false;
      if(res.resultado == 1){
        this.todasRecepciones = res.recepciones;
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
      else this.initModal()
    },
    recepciones: function(){
      if (this.recepciones.length > 0){
        for (let id in this.recepciones){
          this.recepciones[id].fecha = moment(this.recepciones[id].fecha).format("DD/MM/YYYY")
        }
      }
    }
  },
}
</script>

<style>

</style>